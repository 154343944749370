import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

export interface FreezerDetails {
  FreezerID: number;
  Description: string;
  UserID: number;
  Shelves?: ShelfDetails[];
}
export interface ShelfDetails {
  ShelfID: number;
  FreezerID: number;
  Description: string;
  FoodItems?: FoodItemDetails[];
}
export interface FoodItemDetails {
  ItemID?: number;
  ShelfID?: number;
  Description: string;
  DateAddedTimeStamp: number;
  Removed?: boolean;
  Quantity: number;
  QuantityUnits: number;
  Comments?: string;
  UserID?: number;
  Number?: number;
}

export interface QuantityUnitDetails {
  UnitID: number;
  UnitText: string;
}

interface NamesResponse {
  All: string[];
  Present: string[];
}

@Injectable({
  providedIn: 'root'
})
export class FreezerService {
  private freezers = new BehaviorSubject<FreezerDetails[]>(null);
  public freezersObs = this.freezers.asObservable();
  public quantityUnits: QuantityUnitDetails[];
  private allNames = new BehaviorSubject<string[]>(null);
  public allNamesObs = this.allNames.asObservable();
  private presentNames = new BehaviorSubject<string[]>(null);
  public presentNamesObs = this.presentNames.asObservable();
  private allFoodItems = new BehaviorSubject<FoodItemDetails[]>(null);
  public allFoodItemsObs = this.allFoodItems.asObservable();
  private freezerList: FreezerDetails[];
  private shelfList: ShelfDetails[];
  public searchText: string = '';
  constructor(private http: HttpClient, private userService: UserService) {
    this.refresh();
  }

  public refresh(): void {
    this.getFreezerDetails();
    this.getUnits();
    this.getNames();
  }

  private getFreezerDetails(): void {
    console.log(this.userService.getUserID());
    this.http.get<FreezerDetails[]>(environment.apiURL + '/freezers').subscribe((response) => {
      console.log(response);
      this.freezers.next(response);
      this.getFoodItemsList();
    });
  }

  private getUnits(): void {
    this.http.get<QuantityUnitDetails[]>(environment.apiURL + '/quantityUnits').subscribe((response) => {
      console.log(response);
      this.quantityUnits = response;
    })
  }

  private getNames(): void {
    this.http.get<NamesResponse>(environment.apiURL + '/items/names').subscribe((response) => {
      console.log(response);
      this.allNames.next(response.All);
      this.presentNames.next(response.Present);
    })
  }

  private getFoodItemsList(): void {
    const foodItemsList: FoodItemDetails[] = [];
    this.freezerList = [];
    this.shelfList = [];
    for(let freezer of this.freezers.getValue()) {
      this.freezerList.push(freezer);
      for(let shelf of freezer.Shelves) {
        this.shelfList.push(shelf);
        for(let foodItem of shelf.FoodItems) {
          foodItemsList.push(foodItem);
        }
      }
    }
    this.allFoodItems.next(foodItemsList);
  }

  public getUnitText(unitID: number): string {
    if(this.quantityUnits) {
      for (let quantityUnit of this.quantityUnits) {
        if(quantityUnit.UnitID === unitID) {
          return quantityUnit.UnitText;
        }
      }
    }
    return "";
  }

  public getShelfText(shelfID: number): string {
    let shelfDescription: string;
    let freezerID: number;
    let freezerDescription: string;
    let shelfText: string;
    for(let shelf of this.shelfList) {
      if(shelf.ShelfID === shelfID) {
        shelfDescription = shelf.Description;
        freezerID = shelf.FreezerID;
      }
    }
    for(let freezer of this.freezerList) {
      if(freezer.FreezerID === freezerID) {
        freezerDescription = freezer.Description;
      }
    }
    shelfText = `${freezerDescription} > ${shelfDescription}`;
    return shelfText;
  }

  public addItem(newItem: FoodItemDetails) {
    return this.http.put<FoodItemDetails>(environment.apiURL + '/items/add', newItem);
  }

  public updateItem(newItemDetails: FoodItemDetails) {
    return this.http.post<FoodItemDetails>(environment.apiURL + '/items/update', newItemDetails);
  }

  public getDateString(date: Date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
  }
}
