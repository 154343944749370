import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public pin: string = "";
  public shake: boolean = false;
  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
  }

  public addDigit(digit: number) {
    this.pin += String(digit);
    console.log(this.pin);
    if(this.pin.length === 4) {
      this.login();
    }
  }

  public removeDigit() {
    this.pin = this.pin.substr(0, this.pin.length-1);
    console.log(this.pin);
  }

  public clear() {
    this.pin = "";
    console.log(this.pin);
  }

  private login() {
    console.log('Login');
    this.userService.login(this.pin).subscribe(
      (data) => {
        this.userService.processLoginResponse(data);
        this.clear();
        console.log(this.userService.isLoggedIn());
        this.router.navigate(['/home']);
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.shake = true;
        setTimeout(() =>
          {
            this.shake = false;
            this.clear();
          },
          1000);
      }
    );
  }
}
