import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FoodItemDetails, FreezerDetails, FreezerService, ShelfDetails } from 'src/app/services/freezer.service';

@Component({
  selector: 'app-move-form',
  templateUrl: './move-form.component.html',
  styleUrls: ['./move-form.component.css']
})
export class MoveFormComponent implements OnInit {
  private itemID: number;
  private item: FoodItemDetails;
  private selectedFreezerID: number = null;
  public shelfList: ShelfDetails[] = [];
  public freezers: FreezerDetails[];
  public shelves: ShelfDetails[];
  public moveForm = this.fb.group({
    Freezer: ['', Validators.required],
    Shelf: ['', Validators.required],
  });
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private freezerService: FreezerService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!isNaN(Number(params['id']))) {
        this.itemID = Number(params['id']);
      }
    });
    this.freezerService.freezersObs.subscribe((freezers) => {
      this.freezers = [];
      this.shelves = [];
      if(freezers) {
        for(let freezer of freezers) {
          this.freezers.push(freezer);
          for(let shelf of freezer.Shelves) {
            this.shelves.push(shelf);
            for(let item of shelf.FoodItems) {
              if(item.ItemID === this.itemID) {
                this.item = item;
              }
            }
          }
        }
      }
    });
  }

  public freezerChange(freezerID: number): void {
    console.log(this.moveForm.value.Freezer);
    this.selectedFreezerID = this.moveForm.value.Freezer;
    this.filteredShelves();
  }

  public filteredShelves(): void {
    if(this.selectedFreezerID) {
      this.shelfList = this.shelves.filter(shelf => shelf.FreezerID === this.selectedFreezerID);
    } else {
      this.shelfList = [];
    }
    console.log(this.shelfList);
  }

  public update(): void {
    console.log(this.item);
    console.log(this.moveForm.value.Shelf);
    this.item.ShelfID = this.moveForm.value.Shelf;
    console.log(this.item);
    this.freezerService.updateItem(this.item).subscribe((data) => {
      console.log(data);
      this.freezerService.refresh();
      this.router.navigate(['/fooditem'], { queryParams: { id: this.itemID } });
    });
  }

  public cancel(): void {
    this.router.navigate(['/fooditem'], { queryParams: { id: this.itemID } });
  }
}
