import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FreezerService, ShelfDetails } from 'src/app/services/freezer.service';

@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.css']
})
export class ShelfComponent implements OnInit {
  private shelfID: number;
  public shelf: ShelfDetails;
  constructor(
    private route: ActivatedRoute,
    private freezerService: FreezerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!isNaN(Number(params['id']))) {
        this.shelfID = Number(params['id']);
      }
    });
    this.freezerService.freezersObs.subscribe((freezers) => {
      if(freezers) {
        for(let freezer of freezers) {
          for(let shelf of freezer.Shelves) {
            if(shelf.ShelfID === this.shelfID) {
              this.shelf = shelf;
            }
          }
        }
      }
    });
  }

  public selectFoodItem(itemID: number) {
    this.router.navigate(['/fooditem'], { queryParams: { id: itemID } });
  }

  public back() {
    this.router.navigate(['/freezer'], { queryParams: { id: this.shelf.FreezerID } });
  }

  public addItem() {
    this.router.navigate(['/fooditemform'], { queryParams: { shelfId: this.shelfID } });
  }

  public home():void {
    this.router.navigate(['/home']);
  }
}
