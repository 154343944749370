import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { FoodItemFormComponent } from './components/food-item-form/food-item-form.component';
import { FoodItemComponent } from './components/food-item/food-item.component';
import { FreezerComponent } from './components/freezer/freezer.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MoveFormComponent } from './components/move-form/move-form.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { ShelfComponent } from './components/shelf/shelf.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { animationState: 'Two' } },
  { path: 'login', component: LoginComponent, data: { animationState: 'One' }},
  { path: 'freezer', component: FreezerComponent, data: { animationState: 'Three' }},
  { path: 'shelf', component: ShelfComponent, data: { animationState: 'Four' }},
  { path: 'fooditem', component: FoodItemComponent, data: { animationState: 'Five' }},
  { path: 'fooditemform', component: FoodItemFormComponent, data: { animationState: 'Six' }},
  { path: 'moveform', component: MoveFormComponent, data: { animationState: 'Six' }},
  { path: 'search', component: SearchPageComponent, data: { animationState: 'Three' }},
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
