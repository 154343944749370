import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FoodItemDetails, FreezerService, QuantityUnitDetails } from 'src/app/services/freezer.service';

@Component({
  selector: 'app-food-item-form',
  templateUrl: './food-item-form.component.html',
  styleUrls: ['./food-item-form.component.css']
})
export class FoodItemFormComponent implements OnInit {
  public itemID: number;
  private shelfID: number;
  public item: FoodItemDetails;
  public names: string[];
  public autoCompleteList: string[] = [];
  public foodItemForm = this.fb.group({
    Description: ['', Validators.required],
    DateAdded: ['', Validators.required],
    Quantity: ['', Validators.required],
    QuantityUnits: ['', Validators.required],
    Comments: [''],
    Number: ['']
  });
  constructor(
    private route: ActivatedRoute,
    public freezerService: FreezerService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!isNaN(Number(params['id']))) {
        this.itemID = Number(params['id']);
        this.freezerService.freezersObs.subscribe((freezers) => {
          if(freezers) {
            for(let freezer of freezers) {
              for(let shelf of freezer.Shelves) {
                for(let item of shelf.FoodItems) {
                  if(item.ItemID === this.itemID) {
                    this.item = item;
                    this.shelfID = item.ShelfID;
                    console.log(this.item);
                    this.foodItemForm.patchValue({
                      Description: this.item.Description,
                      DateAdded: this.freezerService.getDateString(new Date(this.item.DateAddedTimeStamp)),
                      Quantity: this.item.Quantity,
                      QuantityUnits: this.item.QuantityUnits,
                      Comments: this.item.Comments
                    });
                  }
                }
              }
            }
          }
        });
      } else {
        if(!isNaN(Number(params['shelfId']))) {
          this.shelfID = Number(params['shelfId']);
          this.foodItemForm.patchValue({
            DateAdded: this.freezerService.getDateString(new Date()),
            Number: 1
          });
        }
      }
    });
    this.freezerService.allNamesObs.subscribe((names) => {
      if(names) {
        this.names = names;
        console.log(this.names);
      }
    });
    this.foodItemForm.valueChanges.subscribe((value) => {
      console.log(value.Description);
      this.updateAutoCompleteList(value.Description);
    });
  }

  public valueChange(): void {
    console.log(this.foodItemForm.value);
    const dateAdded = new Date(this.foodItemForm.value.DateAdded);
    console.log(dateAdded);
  }

  public updateAutoCompleteList(description: string): void {
    console.log('Change');
    let autoCompleteList: string[] = [];
    if(this.foodItemForm.value.Description.length >= 2) {
      autoCompleteList = this.names.filter(name => name.toLowerCase().includes(description.toLowerCase()));
    }
    this.autoCompleteList = autoCompleteList.slice(0,5);
  }

  public back() {
    if(this.item) {
      this.router.navigate(['/fooditem'], { queryParams: { id: this.item.ItemID } });
    } else {
      this.router.navigate(['/shelf'], { queryParams: { id: this.shelfID } });
    }
  }

  public add() {
    console.log(this.foodItemForm.value);
    let newItem: FoodItemDetails = this.foodItemForm.value;
    newItem.DateAddedTimeStamp = new Date(this.foodItemForm.value.DateAdded).getTime();
    newItem.ShelfID = this.shelfID;
    this.freezerService.addItem(newItem).subscribe((data) => {
      console.log(data);
      this.freezerService.refresh();
      this.router.navigate(['/shelf'], { queryParams: { id: this.shelfID } });
    });
  }

  public update() {
    console.log(this.foodItemForm.value);
    let newItemDetails: FoodItemDetails = this.foodItemForm.value;
    newItemDetails.DateAddedTimeStamp = new Date(this.foodItemForm.value.DateAdded).getTime();
    newItemDetails.ShelfID = this.shelfID;
    newItemDetails.ItemID = this.itemID;
    newItemDetails.Removed = false;
    this.freezerService.updateItem(newItemDetails).subscribe((data) => {
      console.log(data);
      this.freezerService.refresh();
      this.router.navigate(['/shelf'], { queryParams: { id: this.shelfID } });
    });
  }

}
