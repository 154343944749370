import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

interface LoginResponse {
  jwt: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public login(pin: string) {
    const payload = { PIN: pin }
    return this.http.post<LoginResponse>(environment.apiURL + '/user/login', payload);
  }

  public processLoginResponse(response: LoginResponse) {
    sessionStorage.setItem('Jwt', response.jwt);
  }

  public isLoggedIn(): boolean {
    if (sessionStorage.getItem('Jwt')) {
      return true;
    } else {
      return false;
    }
  }

  public getJWT(): string {
    return sessionStorage.getItem('Jwt');
  }

  public logOut(): void {
    sessionStorage.clear();
  }

  public getUserID(): number {
    const jwt = sessionStorage.getItem('Jwt');
    try {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(jwt);
      console.log(decodedToken);
      if (decodedToken.UserDetails.UserID) {
        return decodedToken.UserDetails.UserID;
      } else {
        return null;
      }
    }
    catch (Error) {
      console.log(Error);
      return null;
    }
  }
}
