<div class="container">
  <div class="item">
    <div class="dot-container" [ngClass]="{'shakeit': shake === true }">
      <div class="dot-item" [ngClass]="{'dot-item-full': pin.length >= 1}"></div>
      <div class="dot-item" [ngClass]="{'dot-item-full': pin.length >= 2}"></div>
      <div class="dot-item" [ngClass]="{'dot-item-full': pin.length >= 3}"></div>
      <div class="dot-item" [ngClass]="{'dot-item-full': pin.length >= 4}"></div>
    </div>
    <div class="button-container">
      <div class="button-item">
        <button class="button" (click)="addDigit(1)">1</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(2)">2</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(3)">3</button>
      </div>
    </div>
    <div class="button-container">
      <div class="button-item">
        <button class="button" (click)="addDigit(4)">4</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(5)">5</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(6)">6</button>
      </div>
    </div>
    <div class="button-container">
      <div class="button-item">
        <button class="button" (click)="addDigit(7)">7</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(8)">8</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(9)">9</button>
      </div>
    </div>
    <div class="button-container">
      <div class="button-item">
        <button class="button" (click)="clear()">C</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="addDigit(0)">0</button>
      </div>
      <div class="button-item">
        <button class="button" (click)="removeDigit()">&lt;</button>
      </div>
    </div>
  </div>
</div>
