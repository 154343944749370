import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FoodItemDetails, FreezerService } from 'src/app/services/freezer.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})
export class SearchPageComponent implements OnInit {
  public searchForm = this.fb.group({
    Search: ['']
  });
  private allFoodItems: FoodItemDetails[];
  public filteredFoodItems: FoodItemDetails[];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private freezerService: FreezerService
  ) { }

  ngOnInit(): void {
    this.freezerService.allFoodItemsObs.subscribe(foodItems => {
      console.log('FoodItems');
      console.log(foodItems);
      this.allFoodItems = foodItems;
    });
    this.searchForm.patchValue({Search: this.freezerService.searchText});
    this.search(this.freezerService.searchText);
    this.searchForm.get("Search").valueChanges.subscribe(search => {
      console.log('Search value changed');
      console.log(search);
      this.freezerService.searchText = search;
      this.search(search);
    });
  }

  private search(search: string) {
    if(search.length > 2) {
      console.log('Y');
      this.filteredFoodItems = this.allFoodItems.filter(foodItem => foodItem.Description.toLowerCase().includes(search.toLowerCase()));
      console.log(this.filteredFoodItems);
    } else {
      console.log('N');
      this.filteredFoodItems = [];
    }
  }

  public clear(): void {
    this.searchForm.patchValue({Search: ''});
    this.search('');
  }

  public back():void {
    this.freezerService.searchText = '';
    this.router.navigate(['/home']);
  }
}
