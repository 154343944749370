import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { UserService } from './user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor{

  constructor(private router: Router, private userService: UserService) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
        console.log('AUTH ERROR');
        this.router.navigate(['/login']);
        return throwError(err.message);
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(req.url);
    const requestURL = req.url;
    if (requestURL.includes(environment.apiURL)) {
      console.log('API true');
      console.log(this.userService);
      if (this.userService.isLoggedIn()) {
        console.log('Logged In');
        const jwt = this.userService.getJWT();
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(jwt);
        console.log(decodedToken.exp);
        const now = Math.round(new Date().getTime() / 1000);
        console.log(now);
        const expiresIn: number = decodedToken.exp - now;
        console.log(expiresIn);
        if (expiresIn < 0) {
          console.log('Token Expired');
          // Destroy token
          this.userService.logOut();
          // Route back home
          this.router.navigate(['/login']);
        } else {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`
            }
          });
        }
      }
    }
    return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
  }
}
