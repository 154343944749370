import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FoodItemDetails, FreezerService } from 'src/app/services/freezer.service';

@Component({
  selector: 'app-food-item',
  templateUrl: './food-item.component.html',
  styleUrls: ['./food-item.component.css']
})
export class FoodItemComponent implements OnInit {
  private itemID: number;
  public item: FoodItemDetails;
  private origin: string;
  constructor(
    private route: ActivatedRoute,
    private freezerService: FreezerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!isNaN(Number(params['id']))) {
        this.itemID = Number(params['id']);
      }
    });
    this.freezerService.freezersObs.subscribe((freezers) => {
      if(freezers) {
        for(let freezer of freezers) {
          for(let shelf of freezer.Shelves) {
            for(let item of shelf.FoodItems) {
              if(item.ItemID === this.itemID) {
                this.item = item;
              }
            }
          }
        }
      }
    });
    this.origin = window.history.state.redirect;
    console.log(window.history.state.redirect);
  }

  public getUnitText(unitID: number): string {
    return this.freezerService.getUnitText(unitID);
  }

  public getShelfDescription(shelfID: number): string {
    return this.freezerService.getShelfText(shelfID);
  }

  public back() {
    if(this.origin === '/search') {
      this.router.navigate(['/search']);
    } else {
      this.router.navigate(['/shelf'], { queryParams: { id: this.item.ShelfID } });
    }
  }

  public editItem() {
    this.router.navigate(['/fooditemform'], { queryParams: { id: this.itemID } });
  }

  public removeItem() {
    if(confirm("Are you sure?")) {
      this.item.Removed = true;
      this.freezerService.updateItem(this.item).subscribe((data) => {
        console.log(data);
        this.freezerService.refresh();
        this.router.navigate(['/shelf'], { queryParams: { id: this.item.ShelfID } });
      });
    }
  }

  public moveItem() {
    this.router.navigate(['/moveform'], { queryParams: { id: this.itemID } })
  }

  public home() {
    this.router.navigate(['/home']);
  }
}
