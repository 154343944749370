<div class="container">
  <div class="item">
    <div class="title flex">
      <div class="flex-item">
        <button class="button nav-button" (click)="back()">&lt;</button>
      </div>
      <div class="flex-item">
        {{ getShelfDescription(item.ShelfID) }}
      </div>
    </div>
    <div class="list">
      <div class="list-item">
        <div>{{ item.Description }}</div>
        <div class="smaller">Date Added: {{ item.DateAddedTimeStamp | date: "dd.MM.yyyy" }}</div>
        <div class="smaller">{{ item.Quantity }} {{ getUnitText(item.QuantityUnits) }}</div>
        <div class="smaller">{{ item.Comments }}</div>
      </div>
      <div class="button-space">
        <button class="button" (click)="editItem()">Edit</button>
      </div>
      <div class="button-space">
        <button class="button" (click)="moveItem()">Move</button>
      </div>
      <div class="button-space">
        <button class="button" (click)="removeItem()">Remove</button>
      </div>
      <div class="button-space">
        <button class="button" (click)="home()">Home</button>
      </div>
    </div>
  </div>
</div>
