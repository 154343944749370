<div class="container">
  <div class="item">
    <form [formGroup]="searchForm">
      <div class="title flex">
        <div class="flex-item">
          <input type="text" class="input" formControlName="Search" placeholder="Search">
        </div>
        <div class="flex-item">
          <button class="button nav-button" (click)="clear()">X</button>
        </div>
      </div>
    </form>
    <div class="list">
      <app-food-item-list-element *ngFor="let item of filteredFoodItems" [foodItemDetails]="item"></app-food-item-list-element>
    </div>
    <div class="button-space">
      <button class="button" (click)="back()">Home</button>
    </div>
  </div>
</div>

