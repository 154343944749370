<div class="container">
  <div class="item">
      <div class="title flex">
        <div class="flex-item">
          <button class="button nav-button" (click)="back()">&lt;</button>
        </div>
        <div class="flex-item">
          {{ shelf.Description }}
        </div>
      </div>
    <div class="list">
      <app-food-item-list-element *ngFor="let item of shelf.FoodItems" [foodItemDetails]="item"></app-food-item-list-element>
    </div>
    <div class="button-space">
      <button class="button" (click)="addItem()">Add Item</button>
    </div>
    <div class="button-space">
      <button class="button" (click)="home()">Home</button>
    </div>
  </div>
</div>

