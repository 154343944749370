<div class="container">
  <div class="item">
    <div class="title">
      Item Details
    </div>
    <div class="list">
      <form [formGroup]="moveForm">
        <div class="form">
          <select class="input" formControlName="Freezer" (change)="freezerChange($event.target.value)">
            <option value=""></option>
            <option *ngFor="let freezer of freezers" [ngValue]="freezer.FreezerID">{{ freezer.Description }}</option>
          </select>
          <select class="input" formControlName="Shelf">
            <option value=""></option>
            <option *ngFor="let shelf of shelfList" [ngValue]="shelf.ShelfID">{{ shelf.Description }}</option>
          </select>
        <!-- </div> -->
        </div>
      </form>
      <div class="button-space">
        <button class="button" [disabled]="!moveForm.valid" (click)="update()">Move</button>
      </div>
      <div class="button-space">
        <button class="button" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
