<div class="container">
  <div class="item">
    <div class="title">
      Item Details
    </div>
    <div class="list">
      <form [formGroup]="foodItemForm">
        <div class="form">
          <input type="text" class="input" formControlName="Description" placeholder="Description" list="descriptions">
          <datalist id="descriptions">
            <option *ngFor="let name of autoCompleteList">{{ name }}</option>
          </datalist>
          <input class="input" type="date" formControlName="DateAdded" (click)="valueChange()">
          <div class="subform">
            <div class="w50">
              <input type="number" class="input" formControlName="Quantity" placeholder="Qty">
            </div>
            <div class="w50">
              <select class="input" formControlName="QuantityUnits">
                <option value=""></option>
                <option *ngFor="let quantityUnit of this.freezerService.quantityUnits" [ngValue]="quantityUnit.UnitID">{{ quantityUnit.UnitText }}</option>
              </select>
            </div>
          </div>
          <textarea class="input" formControlName="Comments" placeholder="Comments"></textarea>
          <div class="flex" *ngIf="!itemID">
            <div class="flex-item">
              x
            </div>
            <div class="flex-item">
              <input type="number" class="input" formControlName="Number" min="1" max="10" placeholder="Number">
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="itemID" class="button-space">
        <button class="button" [disabled]="!foodItemForm.valid" (click)="update()">Update</button>
      </div>
      <div *ngIf="!itemID" class="button-space">
        <button class="button" [disabled]="!foodItemForm.valid" (click)="add()">Add</button>
      </div>
      <div class="button-space">
        <button class="button" (click)="back()">Cancel</button>
      </div>
    </div>
  </div>
</div>
