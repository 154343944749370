<div class="container">
  <div class="item">
    <div class="title">
      Freezers
    </div>
    <div class="list">
      <div class="list-item flex" *ngFor="let freezer of freezers">
        <div class="flex-item">
          <button class="button nav-button" (click)="selectFreezer(freezer.FreezerID)">&gt;</button>
        </div>
        <div class="flex-item">
          {{ freezer.Description }}
        </div>
      </div>
      <div class="button-space">
        <button class="button" (click)="search()">Search</button>
      </div>
      <div class="button-space">
        <button class="button" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>

