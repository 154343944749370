<div class="container">
  <div class="item">
    <div class="title flex">
      <div class="flex-item">
        <button class="button nav-button" (click)="back()">&lt;</button>
      </div>
      <div class="flex-item">
        {{ freezer.Description }}
      </div>
    </div>
    <div class="list">
      <div class="list-item flex" *ngFor="let shelf of freezer.Shelves">
        <div class="flex-item">
          <button class="button nav-button" (click)="selectShelf(shelf.ShelfID)">&gt;</button>
        </div>
        <div class="flex-item">
          {{ shelf.Description }}
        </div>
      </div>
    </div>
    <div class="button-space">
      <button class="button" (click)="back()">Home</button>
    </div>
  </div>
</div>

