import { transition, trigger, style, query, animateChild, animate, group } from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
  transition('One => Two, Two => Three, Three => Four, Four => Five, Five=> Six, One => Three, Three => Five', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('400ms ease-out', style({ right: '100%', opacity: 0 }))]),
      query(':enter', [animate('400ms ease-out', style({ right: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('Six => Five, Five => Four, Four => Three, Three => Two, Two => One, Three => One, Four => Two, Five => Two, Five => Three', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('400ms ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('400ms ease-out', style({ left: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ]),
]);
