import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './services/auth-interceptor.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { FreezerComponent } from './components/freezer/freezer.component';
import { ShelfComponent } from './components/shelf/shelf.component';
import { FoodItemComponent } from './components/food-item/food-item.component';
import { FoodItemFormComponent } from './components/food-item-form/food-item-form.component';
import { MoveFormComponent } from './components/move-form/move-form.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { FoodItemListElementComponent } from './components/food-item-list-element/food-item-list-element.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    FreezerComponent,
    ShelfComponent,
    FoodItemComponent,
    FoodItemFormComponent,
    MoveFormComponent,
    SearchPageComponent,
    SearchFormComponent,
    SearchResultsComponent,
    FoodItemListElementComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [Router, UserService] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
