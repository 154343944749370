import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FreezerDetails, FreezerService, ShelfDetails } from 'src/app/services/freezer.service';

@Component({
  selector: 'app-freezer',
  templateUrl: './freezer.component.html',
  styleUrls: ['./freezer.component.css']
})
export class FreezerComponent implements OnInit {
  private freezerID: number;
  public freezer: FreezerDetails;
  constructor(
    private route: ActivatedRoute,
    private freezerService: FreezerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (!isNaN(Number(params['id']))) {
        this.freezerID = Number(params['id']);
      }
    });
    this.freezerService.freezersObs.subscribe((freezers) => {
      if(freezers) {
        for(let freezer of freezers) {
          if(freezer.FreezerID === this.freezerID) {
            this.freezer = freezer;
          }
        }
      }
    });
  }

  public selectShelf(shelfID: number) {
    this.router.navigate(['/shelf'], { queryParams: { id: shelfID } });
  }

  public back() {
    this.router.navigate(['/home']);
  }
}
