import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FoodItemDetails, FreezerService } from '../../services/freezer.service'

@Component({
  selector: 'app-food-item-list-element',
  templateUrl: './food-item-list-element.component.html',
  styleUrls: ['./food-item-list-element.component.css']
})
export class FoodItemListElementComponent implements OnInit {
  @Input() foodItemDetails: FoodItemDetails;

  constructor(
    public freezerService: FreezerService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  public selectFoodItem() {
    console.log(this.foodItemDetails.ItemID);
    this.router.navigate(['/fooditem'], { queryParams: { id: this.foodItemDetails.ItemID }, state: { redirect: this.router.url } }, );
  }
}
