import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FreezerService, FreezerDetails } from 'src/app/services/freezer.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public freezers: FreezerDetails[];
  constructor(private userService: UserService, private freezerService: FreezerService, private router: Router) { }

  ngOnInit(): void {
    this.freezerService.freezersObs.subscribe((freezers) => {
      if(freezers) {
        this.freezers = freezers;
      }
    });
    this.freezerService.refresh();
  }

  public selectFreezer(freezerID: number): void {
    this.router.navigate(['/freezer'], { queryParams: { id: freezerID } });
  }

  public search(): void {
    this.router.navigate(['/search']);
  }

  public logout(): void {
    this.userService.logOut();
    this.router.navigate(['/login']);
  }
}
